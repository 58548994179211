import React from "react"
import { useTranslation } from "react-i18next"

import Image from "./Image"
import "../styles/OurActivities.styl"
import { useEffect } from "react"
import { useState } from "react"
import i18next from "i18next"

export default function OurActivities() {
  const { t } = useTranslation()

  let apiData = Object.values(t("API"))

  return (
    <>
      <div className="activities__wrapper">
        <h1 className="activities__title">{t("OurA.title")}</h1>
        <div className="activities">
          <Image name="medicine.jpg" />
          <div className="info">
            <p className="info__text">{t("OurA.100")}</p>
          </div>
        </div>
        <div className="activities">
          <Image name="hands.jpg" />
          <div className="info">
            <p className="info__text">{t("OurA.supplier")}</p>
          </div>
        </div>

        <div className="activities">
          <Image name="import.jpg" />
          <div className="info">
            <p className="info__text">{t("OurA.import")}</p>
          </div>
        </div>
      </div>
      <div className="apis">
        <h2 className="apis__title">
          Somos líderes comercializando los siguientes API’s
        </h2>
        <ul className="apis__list">
          {apiData.map(i => {
            return (
              <div className="" key={i.name + Math.random().toString()}>
                <h4>{i.title}</h4>
                {Object.values(i).map(is => (
                  <div key={is.name + "abcde"}>{is.name}</div>
                ))}
              </div>
            )
          })}
        </ul>

        <h2 className="apis__title">Nueva línea de API's</h2>
        <ul className="apis__list">
          {Object.values(t("APINew")).map(i => {
            return (
              <div className="" key={i.name + Math.random().toString()}>
                <h4>{i.name}</h4>
                {Object.values(i).map(is => (
                  <div key={is.name + "abcde"}>{is.name}</div>
                ))}
              </div>
            )
          })}
        </ul>
      </div>
      <h2 className="apis__note text text--Blue">{t("OurA.note")}</h2>
      <h3 className="apis__find text text--Blue">
        {t("OurA.find")} <br />
        {t("OurA.touch")}
      </h3>
    </>
  )
}
